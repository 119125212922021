$mobile-header-height: 16rem;
$content-background: rgba(255,255,255,.9);

html { font-size: 62.5%; }

* {
    box-sizing: border-box;
}

html, body {
    margin: 0px;
    padding: 0px;
}

body {
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    font-size: 1.8rem;
}
a, a:link, a:active {
    color: inherit;
    text-decoration: none;
}  

h1, h2, h3, p {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

h1, h2 {
    font-weight: 100;
    margin: 2.2rem 0 1.1rem 0;
    padding: 0;
}

h1.double-margin, h2.double-margin {
    margin-top: 4.4rem;
}

h1:first-child {
    margin-top: 0;
}
h2 {
    font-size: 2.7rem;
}
h3 {
    font-weight: 400;
    margin: 0 0 0.55rem 0;
    font-size: 1.8rem;
}

p {
    margin: 0;
    margin-bottom: 1.1rem;
    &.double-margin {
        margin-bottom: 2.2rem;
    }
    &.half-margin {
        margin-bottom: 0.55rem;
    }
    &:last-child {
        margin-bottom: 0rem;
    }
}

.news-nagbar {
    position: fixed;
    bottom: 0;
    right: 3rem;
    background-color: white;
    z-index: 10000;

    border: 1px #d3d3d3 solid;
    border-bottom: none;
    border-radius: 1.1rem 1.1rem 0 0;
    padding: 1.1rem;
    text-align: center;
}

.mobile-header {
    background-color: black;
    height: $mobile-header-height;
    padding: 2.2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: $mobile-header-height *.5;
    }
}

.navigation {
    position: absolute;
    left: 2.25rem;
    top: $mobile-header-height;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5000;
}

.navigation > .item {
    width: 1.6rem;
    height: 1.6rem;
    margin: .8rem 0;
    background-color: #ededed;
    border-radius: 4px;
    cursor: pointer;
}
.navigation > .item.active {
    background-color: #d3d3d3;
}

nav {
    position: relative;
    font-size: 1.2rem;
    z-index: 500;
    background: rgba(255,255,255,.8);
    a.active {
        font-weight: 500;
    }
    ul, li { list-style: none; margin: 0; padding: 0; white-space: nowrap; }
    > ul {
        display: flex;
        justify-content: center;
    }
    ul {
        li {
            > a, .wrapper {
                padding:  0.5rem 1.5rem;
                display: block;
            }   
        } 
        > li {
            position: relative;
            text-align: center;

            ul {
                visibility: hidden;
                position: absolute;
                top: 100%;  
                left: 0;
                right: 0;

                li {
                    background-color: rgb(255,255,255);
                    text-align: left;
                }  
            }

            &:hover ul, ul:hover {
                visibility: visible;
            }  
        }
    }
}

.container {
    position: relative;
    overflow: hidden auto;
    height: calc(100vh - #{$mobile-header-height});
    scroll-snap-type: y mandatory;
}


.background > .sidebar {
    display: none;

    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
}

.background > .sidebar > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.slide {
    min-height: calc(100vh - #{$mobile-header-height});
    width: 100%;

    overflow: visible;
    scroll-snap-align: start;

}

.background {
    background-repeat: no-repeat;
    background-position: top right 4rem;
    background-size: auto calc(100vh - #{$mobile-header-height});
    height: calc(100vh - #{$mobile-header-height});
    position: sticky;
    top: 0;
    z-index: 0;
}

.content {
    position: relative;
    z-index: 100;

    padding: 6rem;
    max-width: 72rem;

    min-height: calc(100vh - #{$mobile-header-height} );
    margin-top: calc((100vh - #{$mobile-header-height} ) * -1);

    background: $content-background;

    p {
        hyphens: auto;
        text-align: justify;
    }

    figure, p {
        > img {
            max-width: 100%;
        }
    }
}
.content::after{
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 48rem;
    right: -48rem;
    background: linear-gradient(90deg, #{$content-background} 0%, rgba(255,255,255,0) 100%);
}

.bg1 {
    background-image: url(../images/side_flowchart1.svg)
}
.bg2 {
    background-image: url(../images/side_flowchart2.svg)
}
.bg3 {
    background-image: url(../images/side_flowchart3.svg)
}
.bg4 {
    background-image: url(../images/side_flowchart4.svg)
}

.background > .sidebar.portrait {
    background-image: url(../images/thegraf.jpg);
    background-position: bottom center;
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.desktop-sidebar {
    display: none;
    position: absolute;
    z-index: 5000;
    color: white;

    top: 0;
    left: 0;
    bottom: 0;

    padding: 2rem;

    img {
        width: 100%;
    }

    #addr-container {
        opacity: 0;
        transition: opacity .5s ease-in-out;
        &.shown {
            opacity: 100;
        }
    }
}

.button {
	display: inline-block;
	padding: .6rem 1.1rem;
	background-color: #ededed;
	border-radius: .6rem;
	cursor: pointer;
    text-decoration: none;


    &.nerdmode-button-show.active {
        display: none;
    }
    &.nerdmode-button-hide:not(.active) {
        display: none;
    }

    &.cta-button {
        background-color: #2dc125;
        color: white;
    }
}
.nerdmode {
	overflow: hidden;
	visibility: none;
	height: 0;
	.header {
		font-weight: 400;
		margin-top: 2.2rem;
		margin-bottom: 1.1rem;
	}
}
.nerdmode.active {
	height: auto;
	visibility: visible;
    .skills .skill {
        @for $i from 2 through 10 {
            .skill-bar.skill-#{$i*10} {
                &::after {
                    width: $i*10%;
                }
            }
        }
    }
}
.skills {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px -16px -8px;
	.skill {
		margin: 0px 8px 16px 8px;
		.language {
			color: #999;
		}
		.skill-bar {
			width: 128px;
			height: 16px;
			background-color: #ededed;
			&::after {
				content: ' ';
				display: block;
				background-color: #2dc125;
				height: 100%;
				width: 0%;
				transition: width 1s ease-in-out;
			}
		}
	}
}

.references {
    margin: 0 0 -1.1rem 0;

    .reference {
        display: block;
        border: 1px #d3d3d3 solid;
        border-radius: 2.2rem;
        padding: 1.1rem;
        margin: 0 0 1.1rem 0;

        background-color: white;

        .title {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            height: 4rem;
            font-size: 1.6rem;
        }

        .icon {
            margin: 1.1rem 0;
            text-align: center;
            
            img {
                height: 8rem;
            }
        }

        .description {
            text-align: justify;
            word-wrap: break-word;
            overflow-wrap: break-word;
            hyphens: auto;
            font-size: 1.1rem;
        }
    }
}

@mixin responsive-sidebar($width) {
    .background {
        margin-left: $width;
    }
    .background > .sidebar {
        width: $width;
        margin-left: -$width;
    }
    .content {
        margin-left: $width;
    }
    .navigation {
        left: $width + 2.25rem;
    }
    .desktop-sidebar {
        width: $width;
    }
    nav {
        left: $width;
    }
}

@media screen and (orientation: portrait) {
    .content {
        background-color: white;
    }
}

@media screen and (orientation: portrait) and (min-width: 800px) {
    .content {
        background-color: $content-background;
    }
}

@media screen and (min-width: 700px) {
    nav {
        font-size: inherit;
    }
    .references {
        overflow-x: auto;
        display: flex;
        margin: 0 -1.1rem;

        .reference {
            margin: 0 1.1rem;
            flex: 1 1 0px;
            .title {
                font-size: 1.6rem;
            }
        }
    }
}

@media screen and (orientation: landscape) and (min-width: 500px) {
    nav {
        > ul {
            justify-content: flex-end;
        }
        position: absolute;
        left: 0;
        right: 0;
        //top: 2.5rem;
        top: 2rem - 0.5rem;
    }

    .mobile-header {
        display: none;
    }
    
    .container {
        height: 100vh;
    }
    .navigation {
        top: 0rem;
    }
    .desktop-sidebar {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .slide {
        min-height: 100vh;
    }
    .background {
        background-size: auto 100vh;
        height: 100vh;
    }
    .background > .sidebar {
        display: flex;
        background-color: black;

        z-index: 100;
        overflow: hidden;
    }
    .content {
        min-height: 100vh;
        margin-top: -100vh;
    }
    .content::after {
        width: 96rem;
        right: -96rem;
    }

    @include responsive-sidebar(24rem);
}


@media screen and (orientation: landscape) and (min-width: 1000px) {
    @include responsive-sidebar(32rem);
    .content::after{
        width: 48rem;
        right: -48rem;
    }
}

@media screen and (orientation: landscape) and (min-width: 1500px) {
    @include responsive-sidebar(40rem);
    .content::after{
        width: 32rem;
        right: -32rem;
    }
}

@media screen and (orientation: landscape) and (min-width: 2000px) {
    @include responsive-sidebar(60rem);

    nav {
        top: 3rem - 0.5rem;
    }

    html {
        font-size: 75%;
    }

    .desktop-sidebar {
        padding: 3rem;
    }

    .content {
        background: none;
        padding-top: 7rem;
    }
    .content::after{
        display: none;
    }

    .background > .sidebar.portrait {
        background-size: 75% auto;
    }
}

.highlight {
  .hll { background-color: #ffffcc }
  .c { font-style: italic } /* Comment */
  .err { border: 1px solid #AA2222 } /* Error */
  .k { font-weight: bold } /* Keyword */
  .cm { font-style: italic } /* Comment.Multiline */
  .c1 { font-style: italic } /* Comment.Single */
  .cs { font-style: italic } /* Comment.Special */
  .ge { font-style: italic } /* Generic.Emph */
  .gh { font-weight: bold } /* Generic.Heading */
  .gp { font-weight: bolddiv } /* Generic.Prompt */
  .gs { font-weight: bold } /* Generic.Strong */
  .gu { font-weight: bold } /* Generic.Subheading */
  .kc { font-weight: bold } /* Keyword.Constant */
  .kd { font-weight: bold } /* Keyword.Declaration */
  .kn { font-weight: bold } /* Keyword.Namespace */
  .kr { font-weight: bold } /* Keyword.Reserved */
  .s { font-style: italic } /* Literal.String */
  .nc { font-weight: bold } /* Name.Class */
  .ni { font-weight: bold } /* Name.Entity */
  .ne { font-weight: bold } /* Name.Exception */
  .nn { font-weight: bold } /* Name.Namespace */
  .nt { font-weight: bold } /* Name.Tag */
  .ow { font-weight: bold } /* Operator.Word */
  .sb { font-style: italic } /* Literal.String.Backtick */
  .sc { font-style: italic } /* Literal.String.Char */
  .sd { font-style: italic } /* Literal.String.Doc */
  .s2 { font-style: italic } /* Literal.String.Double */
  .se { font-weight: bold; font-style: italic } /* Literal.String.Escape */
  .sh { font-style: italic } /* Literal.String.Heredoc */
  .si { font-weight: bold; font-style: italic } /* Literal.String.Interpol */
  .sx { font-style: italic } /* Literal.String.Other */
  .sr { font-style: italic } /* Literal.String.Regex */
  .s1 { font-style: italic } /* Literal.String.Single */
  .ss { font-style: italic } /* Literal.String.Symbol */
}
@media screen and (orientation: landscape) and (min-width: 1000px) {
    .blog-preview {
        display: flex;
        margin: 0 -1.1rem;

        .blog-post {
            flex: 1 1 0px;
            margin: 0 1.1rem;
        }
    }
}

.blog-preview {
    .tag-batch {
        font-size: 1.1rem;
        margin-right: 0;
        margin-bottom: 1.5rem;
    }
    .blog-post {
        &a {
            display: block;
        }
        .blog-abstract{
            font-size: 1.4rem;
        }
    }
    .blog-date{
        font-size: 1.4rem;
    }
}

.blog-preview-more{
    font-size: 1.4rem;
    margin-top: 2.2rem;
}

.blog-tags{
    margin-bottom: .4rem;
}

.tag-batch {
    flex: 1 1 0px;
    font-weight: 800;
    font-size: 1.4rem;
    padding: 0.8rem 1.2rem;
    background-color: black;
    display: inline-block;
    color: white;
    border-radius: 1.2em;
    margin-right: .5rem;
    margin-bottom: 1.5rem;
    line-height: 1;
}
.tag-batch-small {
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

figure {
    margin: 3rem 2rem;
}

figcaption {
    font-size: 1.4rem;
    text-align: right;
    margin-right: 1.4rem;
}

.header-menu{
    z-index: 20;
}
@for $i from 1 to 13 {
    .mb-#{$i*0.5}{
        margin-bottom: $i*0.5rem;
    }    
}

.product-list-item {
    
    display: flex; 
    flex-direction: row;
    margin-bottom: 3rem;
    .icon {
        img {
            width: 12.8rem;
        }
    }
    .product-content {
        margin-left: 1.5rem;
        > h3 {
            margin-bottom: 1rem;
            
        }
    }
}


